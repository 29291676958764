import * as UI from "@goodgym/components";
import * as menus from "@goodgym/menus";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { State } from "../../../types";

export type SessionTypesFilterProps = UI.BoxProps & {
  state: Pick<State, "sections" | "filters" | "availableTypes" | "drawers">;
};

const SessionTypesFilter: React.FC<SessionTypesFilterProps> = ({
  state,
  ...props
}) => {
  const { filters } = state;
  const sessionType = localStorage.getItem("sessionType");
  const [typeSelected, setTypeSelected] = React.useState(
    sessionType ? sessionType : "all"
  );

  React.useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear(); // Clear localStorage only on page refresh
    };

    // Retrieve sessionType from localStorage and parse it
    const sessionType = localStorage.getItem("sessionType");

    const parsedSessionType =
      sessionType && sessionType !== "all" && sessionType !== "Group Sessions"
        ? sessionType
        : null;

    if (parsedSessionType !== null) {
      filters.update({
        types: [parsedSessionType],
      });
      setTypeSelected(parsedSessionType);
    } else {
      filters.update({
        types: [
          "GroupRun",
          "CommunityMission",
          "Mission",
          "DocumentCheck",
          "Party",
          "TrainingSession",
          "Race",
        ],
      });
    }

    // Add event listener for page unload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UI.Box {...props}>
      <UI.Typography component={"span"} variant="subtitle1">
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1, mb: 2 }}>
            Session types
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>
      <UI.Box>
        <FormControl fullWidth>
          <UI.InputLabel> Sessions Type</UI.InputLabel>
          <UI.Select
            id="session-type-filter"
            label="Sessions Type"
            onChange={(event) => {
              const value = (): Array<string> => {
                switch (event.target.value) {
                  case "all":
                    return [
                      "GroupRun",
                      "CommunityMission",
                      "Mission",
                      "DocumentCheck",
                      "Party",
                      "TrainingSession",
                      "Race",
                    ];
                  case "Group Sessions":
                    return ["GroupRun", "CommunityMission"];
                  default:
                    return [event.target.value];
                }
              };
              filters.update({
                types: value(),
              });
              localStorage.setItem("sessionType", event.target.value);
              setTypeSelected(event.target.value);
            }}
            value={typeSelected}
          >
            <UI.MenuItem value="all">All Session Types</UI.MenuItem>
            <UI.MenuItem value="Group Sessions">Group Sessions</UI.MenuItem>
            {menus.sessions.slice(2).map(({ type }) => (
              <UI.MenuItem key={type} value={type}>
                {type.replace(/([A-Z])/g, " $1").replace(/^\s/, "")}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </FormControl>
      </UI.Box>
    </UI.Box>
  );
};

export default SessionTypesFilter;
