import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import Drawer from "./components/Drawer";
import Filters from "./components/Filters";
import Header from "./components/Header";
import SocialVisitCard from "./components/SocialVisitCard";
import { useSocialVisits } from "./components/hooks";
import ResultCount from "./components/ResultCount";
import { useAuth } from "@goodgym/auth";
import ShowSessionsLink from "./components/Header/ShowSessionsLink";
import NotVerifiedPage from "./components/NotVerifiedPage";
import RunnerNotLoggedIn from "@goodgym/components/RunnerNotLoggedIn";
import NoSocialVisitPage from "./components/NoSocialVisitPage";

const SocialVisitsPage = () => {
  const { runner } = useAuth();
  const state = useSocialVisits();



  const showSocialVisits =
    runner?.homeArea?.socialVisits &&
    state.items?.length > 0;
  
  const startSocialVisitArea =
    !runner?.needsToGetCoachVerified &&
    (state.items?.length === 0 || !runner?.homeArea?.socialVisits);

  const getVerified =
    runner?.needsToGetCoachVerified &&
    (state.items?.length === 0 || !runner?.homeArea?.socialVisits);
  const numberVerified = runner?.homeArea?.pairingVerifiedRunnersCount

  const { error } = state;
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  if (error) return <UI.Error error={error} />;
  if (!runner) return <RunnerNotLoggedIn />;

  return (
    <>
      <UI.Seo title="Social Visits" url="/v3/social-visits" />
      <Header state={state} runnerHomeArea={showSocialVisits} />

      {showSocialVisits ? (
        <>
          <Drawer state={state} />
          <UI.Container
            sx={{
              pt: { xs: "50px", sm: "100px" },
              pb: { xs: 5, sm: 0 },
            }}
          >
            <UI.Box
              sx={{
                flexGrow: 1,
                pt: "80px",
                display: { xs: "block", md: "none" },
              }}
            >
              <ResultCount more={state.more} count={state.items?.length || 0} />
            </UI.Box>
            <UI.Grid container spacing={2} sx={{ my: 2 }}>
              {" "}
              <UI.Grid
                item
                xs={12}
                md={8}
                sx={{ mt: { xs: 2, md: 10 }, mb: 2 }}
              >
                {state.items?.map((coachReferral: T.CoachReferralFragment) => (
                  <UI.Box key={coachReferral.id} sx={{ mb: 2 }}>
                    <SocialVisitCard
                      key={coachReferral.id}
                      coachReferral={coachReferral}
                    />
                  </UI.Box>
                ))}
              </UI.Grid>{" "}
              {md && (
                <UI.Grid item md={4} sx={{ mt: { xs: 2, md: 11 }, mb: 2 }}>
                  <UI.Container>
                    <Filters state={state} />
                    <UI.Box>
                      <ShowSessionsLink />
                    </UI.Box>
                  </UI.Container>
                </UI.Grid>
              )}
            </UI.Grid>
          </UI.Container>
        </>
      ) : (
        <>
          <UI.Container
            sx={{
              pt: { xs: "160px", sm: "150px", md: "250px" },
              pb: { xs: 5, sm: 0 },
            }}
          >
            <UI.Grid container>
              {startSocialVisitArea && (
                <UI.Card sx={{ p: 3 }}>
                  <NoSocialVisitPage
                    area={runner?.homeArea?.name}
                    numberVerified={numberVerified}
                    postcode={state.filters.values.postcode}
                  />
                </UI.Card>
              )}
              {getVerified && (
                <UI.Card sx={{ p: 3 }}>
                  <NotVerifiedPage />
                </UI.Card>
              )}
            </UI.Grid>
          </UI.Container>
        </>
      )}
    </>
  );
};

export default SocialVisitsPage;
