import * as UI from "@goodgym/components";
import { useSocialVisits } from "@goodgym/pages/SocialVisitsPage/components/hooks";
import * as u from "@goodgym/util";


const ShowSocialVisitsLink = () => {
  const state = useSocialVisits();


  return (
    <>
      <UI.Box sx={{ flexGrow: 1, mt: 4 }}>
        {state.items?.length > 0 ? (
          <UI.Card>
            <UI.Box sx={{ px: 2, my: 1, width: { sm: "100%", md: "100%" } }}>
              <UI.Box>
                <UI.Box>
                  <UI.Box
                    sx={{
                      background: "#E11018",
                      width: "fit-content",
                      mb: 2,
                      boxShadow: "-5px 5px 0 -1px black",
                    }}
                  >
                    <UI.Typography
                      sx={{
                        mb: 0.5,
                        px: 1,
                        fontStyle: "normal",
                        fontFamily: "'Barlow Condensed', sans-serif",
                        textTransform: "uppercase",
                      }}
                      color="common.white"
                    >
                      Try out a social visit in your area
                    </UI.Typography>
                  </UI.Box>
                  <UI.Link
                    href={u.links.socialVisits()}
                    variant="h4"
                    sx={{ color: "main", flexWrap: "wrap" }}
                  >
                    Visit {state.items[0].name}
                  </UI.Link>

                  <UI.Typography
                    variant="body1"
                    color="neutral.dark"
                    my={1}
                    sx={{ mb: 3 }}
                  >
                    {state.items[0].reasonForReferral}
                  </UI.Typography>
                  <UI.Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <UI.Emoji emoji="mapPin" sx={{ mr: 0 }} />
                    {state.items[0].distanceFromRunner}
                  </UI.Typography>
                </UI.Box>
              </UI.Box>
            </UI.Box>
          </UI.Card>
        ) : (
          <UI.Box>
            
          </UI.Box>
        )}
      </UI.Box>
    </>
  );
};

export default ShowSocialVisitsLink;
